import { WidgetLayout, WidgetProps } from '../layouts/WidgetLayout';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export interface Column {
  header: string;
  accessor: string;
  hideHeader?: boolean;
  align?: 'left' | 'right' | 'center';
}

interface TableProps<T> extends WidgetProps {
  data: T[];
  columns: Column[];
}

export const TableWidget = <T,>({ title, size, data, columns, hideHeader = true}: TableProps<T>): React.ReactElement => {
  return (
      <WidgetLayout hideHeader={hideHeader} title={title} size={size} disablePadding={true}>
          <TableContainer sx={{borderRadius: hideHeader ? "8px" : "0px"}}>
              <Table>
                  <TableHead>
                      <TableRow>
                          {columns.map((col) => (
                              <StyledTableCell key={col.header} align={col.align || 'left'}>
                                  {col.header}
                              </StyledTableCell>
                          ))}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {data.map((row, rowIndex) => (
                          <StyledTableRow key={rowIndex}>
                              {columns.map((col) => (
                                  <StyledTableCell key={String(col.accessor)} align={col.align || 'left'}>
                                      {getNestedValue(row, col.accessor)} 
                                  </StyledTableCell>
                              ))}
                          </StyledTableRow>
                      ))}
                  </TableBody>
              </Table>
          </TableContainer>
      </WidgetLayout>
  );
};

// Utility function to retrieve nested values based on the accessor (path)
const getNestedValue = (obj: any, accessor: string): any => {
  return accessor.split('.').reduce((o, key) => (o && o[key] !== undefined) ? o[key] : '', obj);
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.customColors.color2,
      color: theme.palette.common.black,
      fontWeight:'bold',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));