import { Tune } from '@mui/icons-material';
import { Box, Container, Grid2, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react';

interface LayoutProps {
    title: string;
    extras? : React.ReactNode[];
    children? : React.ReactNode;
    onEdit? : () => void;
}

const PageLayout : React.FC<LayoutProps> = ({title, children, extras, onEdit}) =>{
    const theme = useTheme();
    return(
        <Container sx={{display:'flex', flexDirection:'column'}}>
            {/* Title and components section */}
            <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                {/* Title */}
                <Typography fontSize={50} fontWeight={'bold'} fontStyle={'italic'} color={theme.palette.customColors.color1}>{title}</Typography>
                {/* Components */}
                <Box sx={{display:'flex',width:"100%", flexFlow:'row-reverse'}}> 
                    <IconButton 
                        onClick={onEdit} 
                        sx={{border:'none', 
                        margin:0, 
                        height:45, 
                        width:50, 
                        borderRadius:'8px', 
                        ":hover":{bgcolor:theme.palette.customColors.color1}, 
                        color:'white', 
                        bgcolor:theme.palette.customColors.color1}}>
                        <Tune/>
                    </IconButton>
                    {extras?.map((item, index)=>(
                        <Box key={index} sx={{marginRight:'10px'}}>
                            {item}
                        </Box>
                    ))}
                </Box>
            </Box>
            <Grid2 sx={{paddingTop:'30px'}} container spacing={2}>
                {children}
            </Grid2>
        </Container>
    );
}

export default PageLayout;