import React, { useEffect, useState } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { TextField } from './TextField'; 
import { SearchSelect } from './SearchSelect';

export interface FieldConfig {
    key: string; // Key must be the same as object accessor and name of the component 
    type: 'text' | 'select'; // Field type (text or select)
    readOnly?: boolean; // Whether the field is read-only
    placeholder?: string; // Placeholder text
    defaultValue?: any; // Default value for the field
    getOptionLabel?: (o : any) => any;
    width?: string; // Custom width for the field
    deriveValue?: (rowData: Record<string, string>) => string; // Calculation logic for derived fields
    dependentFields?: Record<string, string>; // Fields to update based on selected value (maps field keys to object properties)
}

interface MultiLineFormProps {
    fields?: FieldConfig[]; // Configuration for form fields
    initialData?: Record<string, string>[]; // Initial data for rows
    onChange?: (name: string, updatedData: Record<string, string>[]) => void; // Callback to update parent data
    name: string;
}

// MultiLineForm Component
export const MultiLineForm: React.FC<MultiLineFormProps> = ({
    fields = [],
    initialData = [],
    onChange,
    name,
}) => {
    const [rows, setRows] = useState<{ id: number; data: Record<string, string> }[]>(
        initialData.map((data, index) => ({ id: index, data })) || [
            { id: Date.now(), data: Object.fromEntries(fields.map((field) => [field.key, ''])) },
        ]
    );

    useEffect(() => {
        addRow();
    }, []);

    useEffect(() => {
        if (onChange) {
            const updatedData = rows.map((row) => row.data);
            onChange(name, updatedData);
        }
    }, [rows]);

    const addRow = () => {
        setRows((prevRows) => [
            ...prevRows,
            { id: Date.now(), data: Object.fromEntries(fields.map((field) => [field.key, ''])) },
        ]);
    };

    const removeRow = (id: number) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    };

    const handleFieldChange = (
        rowId: number,
        fieldKey: string,
        fieldValue: any,
        selectedObject?: Record<string, any>
    ) => {
        setRows((prevRows) =>
            prevRows.map((row) => {
                if (row.id === rowId) {
                    const updatedData = { ...row.data, [fieldKey]: fieldValue };

                    const fieldConfig = fields.find((field) => field.key === fieldKey);
                    if (fieldConfig?.dependentFields && selectedObject) {
                        Object.entries(fieldConfig.dependentFields).forEach(([dependentKey, objectKey]) => {
                            // Update Selected Field
                            updatedData[dependentKey] = selectedObject[objectKey] || '';
                        });
                    }

                    fields.forEach((field) => {
                        if (field.deriveValue) {
                            // Update Field Value
                            updatedData[field.key] = field.deriveValue(updatedData);
                        }
                    });

                    return { ...row, data: updatedData };
                }
                return row;
            })
        );
    };

    return (
        <FormContainer>
            {rows.map((row) => (
                <FormRow key={row.id}>
                    {fields.map((field) => {
                        switch (field.type) {
                            case 'select':
                                return (
                                    <SearchSelect
                                        key={field.key}
                                        options={field.defaultValue}
                                        sx={{ padding: '0px 5px', width: field.width }}
                                        getOptionLabel={field.getOptionLabel}
                                        placeholder={field.placeholder}
                                        value={row.data[field.key]}
                                        onChange={(event: any) =>
                                            handleFieldChange(row.id, field.key, event.target.value, event.target.value)
                                        }
                                    />
                                );
                            case 'text':
                                return (
                                    <TextField
                                        key={field.key}
                                        sx={{ padding: '0px 5px', width: field.width }}
                                        placeholder={field.placeholder}
                                        value={row.data[field.key] || ''}
                                        readOnly={field.readOnly}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            handleFieldChange(row.id, field.key, event.target.value)
                                        }
                                    />
                                );
                            default:
                                return null;
                        }
                    })}
                    <IconButton onClick={() => removeRow(row.id)}>
                        <Remove />
                    </IconButton>
                </FormRow>
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton onClick={addRow}>
                    <Add />
                </IconButton>
            </Box>
        </FormContainer>
    );
};

// Styled components
const FormRow = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0',
}));

const FormContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}));
