import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ColumnBox = styled(Box)(({theme})=>({
    display:'flex',
    flexFlow:'column',
}));

export const RowBox = styled(Box)(({theme}) => ({
    display:'flex',
    flexFlow:'row',
}));
