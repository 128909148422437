import React, { useState } from 'react';
import { Box } from '@mui/material';
import { WidgetLayout, WidgetProps } from '../layouts/WidgetLayout';
import { LineChart, PieChart, BarChart, SparkLineChart} from '@mui/x-charts';

interface StatWidgetProps extends WidgetProps{
    variant: 'pie' | 'bar' | 'line' | 'spark';
    data: any[];
    xAxis?: any[];
    ref?:any;
}

export const StatWidget : React.FC<StatWidgetProps> = ({title, variant, size, data, xAxis, ref}) => {
    const getVariant = (variant : string, dataSource : any[]) : React.ReactNode => {
        switch(variant){
            case 'pie':
                return <PieChart  height={200} ref={ref} series={[{
                    data:dataSource,
                    innerRadius:60,
                    cx:120,
                    highlightScope: { fade: 'global', highlight: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                }]} />
            case 'bar':
                return <BarChart height={200} ref={ref} xAxis={xAxis} series={[{data:dataSource}]} />
            case 'line':
                return <LineChart height={200} ref={ref} series={[{data:dataSource}]}  xAxis={[{ data: xAxis}]}/>
            case 'spark':
                return  <SparkLineChart data={dataSource} ref={ref} height={200} showHighlight={true} showTooltip={true}/>
            default: 
                return  <PieChart  height={200} ref={ref} series={[{
                    data:dataSource,
                    innerRadius:60,
                    cx:120,
                    highlightScope: { fade: 'global', highlight: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                }]} />
        }
    }
    const [chart, setChart] = useState<React.ReactNode>(getVariant(variant, data));
    return(
        <WidgetLayout title={title} size={size}>
            {chart}
        </WidgetLayout>
    );
}