import { TextField as TextInput} from '@mui/material';
import React from 'react';
import { theme } from '../../configs/theme';

interface TextFieldProps {
    placeholder?: string;
    name?:string,
    onChange?: (e : React.ChangeEvent<any>) => void;
    icon?: React.ReactNode;
    iconAlign?: 'left' | 'right';
    inputStyle?: object;
    readOnly?: boolean,
    sx?: object;
    value?: any;
    multiline?: boolean;
}

export const TextField : React.FC<TextFieldProps> = ({
    placeholder, 
    onChange, 
    icon, 
    iconAlign, 
    inputStyle = {background: theme.palette.background.default}, 
    sx = { width:'100%' }, 
    name, 
    value, 
    multiline = false, 
    readOnly = false 
}) => {
    return(
        <TextInput multiline={multiline} name={name} value={value} placeholder={placeholder} onChange={onChange} sx={sx} variant='standard' 
            slotProps={{
                input:{
                    readOnly: readOnly,
                    disableUnderline:true,
                    startAdornment: icon && iconAlign === 'left' ? icon : null, // Conditionally show the search icon
                    endAdornment: icon && iconAlign === 'right' ? icon : null, // Conditionally show the search icon
                    style:{
                        ...inputStyle,
                        border:'none',
                        borderRadius:'8px',
                        padding: multiline ? '11px' :'8px', // when on multilinemode change padding
                    }
                }
            }}
        />
    );
}