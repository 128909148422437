import { Box, Button, Typography } from "@mui/material";
import React, { lazy, useEffect, useRef, useState } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import { SearchSelect } from "../../components/common/SearchSelect";
import { Column, TableWidget } from "../../components/widgets/TableWidget";
import { Circle, Height, Padding, People, Search } from "@mui/icons-material";
import { StatWidget } from "../../components/widgets/StatWidget";
import { lineData, monthsData } from "../../utils/Utils";
import useAPI from "../../hooks/useAPI";
import { Contract } from "../../models/Contract";
import { useModal } from "../../contexts/ModalContext";
import { useAlert } from "../../contexts/AlertContext";
import { useSearchParams } from "react-router-dom";

// Status-to-Color Mapping
const statusColors: Record<Contract['status'], string> = {
    Förfallen: '#FFF689',
    Utkast:'grey',
    Skickad: '#345995',
    Godkänd: '#0E8E70',
    Avvisad: '#C1666B',
};

function offerRow(contract: Contract){
    const { status, address, city, ...rest } = contract;
    return {
        ...rest,
        status,
        fullAddress: `${address}, ${city}`,
        colorStatus: <Circle sx={{color: statusColors[status]}}/>
    }
}

const offerColumn: Column[] = [
    { header: 'Nr.', accessor: 'id', align: 'left' },
    { header: 'Kund', accessor: 'customer.name', align: 'left' },
    { header: 'Org.Nr', accessor: 'customer.identityNumber', align: 'left' },
    { header: 'Adress', accessor: 'fullAddress', align: 'left' },
    { header: 'Skapad', accessor: 'createdOn', align: 'left' },
    { header: 'Värde', accessor: 'totalValue', align: 'right' },
    { header: 'Status', accessor: 'status', align: 'right' },
    { header: '', accessor: 'colorStatus', align: 'right' },
];

const Offer : React.FC = () =>{
    // Use modal context
    const { open } = useModal();
    // Use alert 
    const { alert } = useAlert();
    // use Search params
    const [searchParams] = useSearchParams();
    // Extract id from search params
    const contractId = searchParams.get('id');
    // Use api to fetch contracts
    const { data, error, callAPI } = useAPI<Contract[]>(`/contract?relations=${['customer']}`);
    const [tableData, setTableData] = useState([]);
    const [pieData, setPieData] = useState([
        {id:1, value:0, label:'Skickade', color:'#345995'},
        {id:2, value:0, label:'Godkända', color:'#0E8E70'},
        {id:3, value:0, label:'Avvisade', color:'#C1666B'},       
        {id:4, value:0, label:'Förfallna', color:'#FFF689'},
    ]);
    // Open modal if id is present in query
    useEffect(() => {
        if(contractId)
        open("ContractActionModal");
    },[contractId]);
    // handle OnEdit
    const handleOnEdit = () => {
    }
    // handle Create Offer
    const handleCreateOffer = () => {
       open("OfferModal");
    }
    // Update Pie data
    const updatePieData = (data: Contract[]) => {
        const updatedPieData = pieData.map((item) => {
            let updatedValue = 0;
            switch (item.label) {
                case 'Avvisade':
                    updatedValue = data.filter((d) => d.status === 'Avvisad').length;
                    break;
                case 'Godkända':
                    updatedValue = data.filter((d) => d.status === 'Godkänd').length;
                    break;
                case 'Skickade':
                    updatedValue = data.filter((d) => d.status === 'Skickad').length;
                    break;
                case 'Utkast':
                    updatedValue = data.filter((d) => d.status === 'Utkast').length;
                    break;
                default:
                    updatedValue = item.value; // Keep the same value if no match
            }
            return { ...item, value: updatedValue }; // Create a new object with the updated value
        });
    
        setPieData(updatedPieData); // Replace the pieData state with the updated array
    };
    // On Mound
    useEffect(() => {
        callAPI();
    },[]);
    // Handle on Data
    useEffect(() => {
        if(data){
            // populate table data
            const tempArray: any = [];
            data.map((item) => {
                tempArray.push(offerRow(item));
            });
            setTableData(tempArray);
            // Update Pie data
            updatePieData(data);
        }
    },[data, error]);
    return(
        <PageLayout
            title="Offert"
            onEdit={handleOnEdit}
            extras={[
                <Button variant='contained' onClick={handleCreateOffer} >Skapa</Button>,     
                <SearchSelect inputStyle={{background:'white'}} placeholder="Sök" icon={<Search/>} iconAlign="right"/>   
            ]}
        >
           
            <StatWidget title="Antal skickade" variant="bar" size={7} xAxis={monthsData} data={lineData}/>
            <StatWidget title="Status" variant="pie" size={5} data={pieData} key={JSON.stringify(pieData)} />
            <TableWidget title="Lista" size={12} data={tableData} columns={offerColumn}/>
        </PageLayout>
    );
} 

export default Offer;