import React, { useState } from 'react';
import { MenuItem } from '../../configs/menuConfig';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface MenuProps {
    menuItem: MenuItem[];
    activeItem: MenuItem;
    isOpen: boolean;
    onClickItem : (title : any) => void;
}
const MenuList : React.FC<MenuProps> = ({menuItem, activeItem, isOpen, onClickItem}) => {
    const [submenuState, setSubmenuState] = useState<{ [key: string]: boolean }>({});

    // Toggle submenu visibility
    const handleSubmenuToggle = (title: string) => {
        setSubmenuState((prevState) => ({...prevState, [title]: !prevState[title]}));
    };
    return(
        <List>
            {menuItem.map((item, index) => (
            <div key={index}>
                <ListItem onClick={(e) => {
                    handleSubmenuToggle(item.title);
                    onClickItem(item);
                    }}
                    sx={{
                        '&:hover *':{
                            color:'rgb(84, 138, 115)',
                            cursor:'pointer',
                        },
                        '*':{
                            color:activeItem.title === item.title ?  "rgb(84, 138, 115)" : 'gray',
                        }
                    }}>
                <ListItemIcon>{item.icon}</ListItemIcon> 
                <ListItemText primary={item.title} primaryTypographyProps={{sx:{minWidth:'100px'}}} />
                    {item.submenu && (<span>{submenuState[item.title] ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>)}
                </ListItem>
                {item.submenu && (
                <Collapse in={submenuState[item.title]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {item.submenu.map((subItem, subIndex) => (
                        <ListItem onClick={(e) => {
                            onClickItem(subItem);
                        }} 
                        sx={{
                            '&:hover *':{
                                color: 'rgb(84, 138, 115)',
                                cursor: 'pointer',
                            },
                            '*':{
                                color: activeItem.title === subItem.title ?  '#548A73' : 'gray',
                            }
                        }} key={subIndex} >
                        <ListItemIcon></ListItemIcon>
                        {isOpen && <ListItemText primaryTypographyProps={{sx:{fontSize:'14px'}}} primary={subItem.title} />}
                        </ListItem>
                    ))}
                    </List>
                </Collapse>
                )}
            </div>
            ))}
        </List>
    );
}

export default MenuList;