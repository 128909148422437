import {Apps, BusinessCenter, CalendarMonth, Category, Checklist, ControlCamera, Diversity3, ListAlt, Logout, PendingActions, Settings as SettingIcon, Speed, SupportAgent, TextSnippet, Wallet } from '@mui/icons-material';
import Inisghts from "../pages/dashboard/Insights";
import React from 'react';
import Overview from '../pages/dashboard/Overview';
import Offer from '../pages/contracts/Offer';
import Project from '../pages/contracts/Project';
import { Order } from '../pages/order/Order';
import { Employee } from '../pages/resources/Employee';
import Invoice from '../pages/invoice/Invoice';
import Salary from '../pages/salary/Salary';
import CaseManagement from '../pages/case/CaseManagement';
import Products from '../pages/articles/Products';
import Services from '../pages/articles/Services';
import Company from '../pages/customers/Company';
import Private from '../pages/customers/Private';
import { Vehicles } from '../pages/resources/Vehicles';
import { TimeReport } from '../pages/timereport/TimeReport';
  
export interface MenuItem {
    title: string;
    to:string;
    icon?: React.ReactNode;
    submenu?: MenuItem[];
    element?: React.ReactNode;
}
 // Menu structure with submenus and icons
const Functional: MenuItem[] = [
    { title: "Dashboard", to:'/', icon: <Speed />, element:<Overview/>,
        submenu: [
            { title: "Översikt", to:'/dashboard/overview', element: <Overview/> },
            { title: "Insikter",to:'/dashboard/insights', element:<Inisghts/>},
        ],
    },
    { title: "Offert", to:'/offert',icon: <Wallet />, element:<Offer/>},
    { title: "Projekt", to:'/project', icon: <ListAlt />, element:<Project/> },
    { title: "Order", to:'/order', icon: <CalendarMonth />, element:<Order/>},
    { title: "Resursplanering", to:'/', icon: <Apps /> },
    { title: "Tidrapportering", to:'/timereport', icon: <PendingActions />, element:<TimeReport/>},
    { title: "Export", to:'/export/invoice', icon: <Checklist />, element: <Invoice/>,
        submenu: [
            { title: "Fakturaunderlag", to:'/export/invoice', element:<Invoice/> },
            { title: "Löneunderlag",to:'/export/salary', element: <Salary/> },
        ]},
    { title: "Ärendehantering", to:'/case/management', icon: <TextSnippet />, element:<CaseManagement/>},
];

// Resource Menu
const Resource : MenuItem[] = [
    { title: "Artiklar", to:'/articles/products', icon: <Category />, element: <Products/>, 
        submenu: [
            { title: "Produkter", to:'/articles/products', element: <Products/> },
            { title: "Tjänster",to:'/articles/services', element: <Services /> },
        ]},
    { title: "Kunder",  to:'/customer/company', icon: <BusinessCenter />, element:<Company/>,
        submenu: [
            { title: "Företag", to:'/customer/company', element: <Company/> },
            { title: "Privat",to:'/customer/private', element: <Private/> },
        ]},
    { title: "Resurser", to:'/resources/personnel', icon: <Diversity3/>, element:<Employee/>,
        submenu: [
            { title: "Medarbetare", to:'/resources/personnel', element:<Employee/>},
            { title: "Fordon",to:'/resources/vehicles', element:<Vehicles/>},
        ]},
];

// Util menu
const Utils : MenuItem[] = [
    { title:"Mallar", to:'/', icon:<ControlCamera/>,
        submenu:[
            { title:'Kund',to:'/' },
            { title:'Medarbetare', to:'/'},
            { title:'Kalendar', to:'/'},
            { title:'Kunskap', to:'/'},
            { title:'Egenskaper', to:'/'},
            { title:'Mics', to:'/'},
        ]
    }];

// Setting Menu
const Settings : MenuItem[] = [
    { title:'Support', to:'', icon:<SupportAgent/>},
    { title:'Inställningar', to:'', icon:<SettingIcon/>},
    { title:'Logga ut', to:'', icon:<Logout/>},
];

export {Functional, Resource, Utils, Settings}