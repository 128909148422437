import { error } from "console";
import React, { useContext, createContext, useState } from "react";

// ModalState 
interface ModalState {
    [key: string] : boolean;
}
// Modal Type properties
interface ModalType {
    modalState: ModalState;
    open: (modalName: string) => void;
    close: (modalName: string) => void;
}
// Create ModalContext 
const ModalContext = createContext<ModalType | null>(null);

export const ModalProvder : React.FC<{children:React.ReactNode}> = ({ children }) => {
    // Setup modal state
    const [modalState, setModalState] = useState<ModalState>({});
    
    // Handle on open
    const open = (modalName: string) => {
        setModalState((prev) => ({
            ...prev,
            [modalName]: true,
        }));
    };

    // Handle on close
    const close = (modalName: string) => {
        setModalState((prev) => ({
            ...prev,
            [modalName]: false,
        }));
    };
    
    return(
        <ModalContext.Provider value={{modalState, open, close}}>
            {children}
        </ModalContext.Provider>
    );
} 

// export custom hook
export const useModal = () => {
    const context = useContext(ModalContext);
    if(!context){
        throw new Error("useModal must be used withing ModalProvider");
    }
    return context;
}