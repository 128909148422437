import React, { useEffect, useState } from 'react';
import { BaseModal, BaseModalProps } from '../../components/modals/BaseModal';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { ColumnBox, RowBox } from '../../styles/commonStyles';
import { ProgressStep } from '../../components/common/ProgressStep';
import { CardLayout } from '../../components/layouts/CardLayout';

export const CaseModal : React.FC<BaseModalProps> = ({name, title, path}) => {
    const [steps, setSteps] = useState<Map<string, boolean>>(new Map([
        ['Skapa ärende', true],
        ['Ärendeuppgifter', false],
    ]));

    const updateStep = (stepName: string, value: boolean) => {
        setSteps((prevSteps) => {
            const updatedSteps = new Map(prevSteps); // Copy the current map
            updatedSteps.set(stepName, value); // Update the specific step value
            return updatedSteps; // Return the updated map
        });
    };

    // Handle Save form
    const handleSubmit = () => {
    }

    return(
        <BaseModal
            name={name}
            path={path}
            title={title}
        >
            <RowBox>
                {/* Informaiton Side */}
                <ColumnBox sx={{width:'30%'}}>
                    <ProgressStep steps={steps}/>
                </ColumnBox>
                {/* Content Side */}
                <ColumnBox sx={{width:'70%'}}>
                    <CardLayout title="Skapa ärende" index={1} description="Ange vilken kund, order och uppdrag ärendet gäller.">
                        <Typography>TEST</Typography>
                    </CardLayout>
                    <CardLayout title="Ärendeuppgifter" index={2} description="Ange uppgifter för ärendet">
                        <Typography>TEST</Typography>
                    </CardLayout>
                    <Box sx={{display:'flex', flexFlow:'row-reverse', padding:'15px'}}>
                        <Button onClick={handleSubmit} variant='contained'>Spara</Button>
                    </Box>
                </ColumnBox>
            </RowBox>
        </BaseModal>
    );
}