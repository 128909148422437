import React, { useState } from 'react';
import { BaseModal, BaseModalProps } from '../../components/modals/BaseModal';
import { Box, Button, Typography } from '@mui/material';
import { ColumnBox, RowBox } from '../../styles/commonStyles';
import { ProgressStep } from '../../components/common/ProgressStep';
import { CardLayout } from '../../components/layouts/CardLayout';

export const VehiclesModal : React.FC<BaseModalProps> = ({name, path, title}) => {
    const [steps, setSteps] = useState<Map<string, boolean>>(new Map([
        ['Skapa fordon', true],
        ['Fordonuppgifter', false],
        ['Datum & Tider', false],
    ]));

    const updateStep = (stepName: string, value: boolean) => {
        setSteps((prevSteps) => {
            const updatedSteps = new Map(prevSteps);
            updatedSteps.set(stepName, value); 
            return updatedSteps; 
        });
    };
    // Handle Save form
    const handleSave = () => {
    }

    return(
        <BaseModal
            name={name}
            path={path}
            title={title}
        >
            <RowBox>
                {/* Informaiton Side */}
                <ColumnBox sx={{width:'30%'}}>
                    <ProgressStep steps={steps}/>
                </ColumnBox>
                {/* Content Side */}
                <ColumnBox sx={{width:'70%'}}>
                    <CardLayout title="Skapa fordon" index={1} description="Skapa?">
                        <Typography>TEST</Typography>
                    </CardLayout>
                    <CardLayout title="Fordonuppgifter" index={2} description="Ange särskilda uppgifter för ditt fordon.?">
                        <Typography>TEST</Typography>
                    </CardLayout>
                    <CardLayout title="Daturm & Tider" index={3} description="När ska fordonet tillgängligt?">
                        <Typography>TEST</Typography>
                    </CardLayout>
                    <Box sx={{display:'flex', flexFlow:'row-reverse', padding:'15px'}}>
                        <Button onClick={handleSave} variant='contained'>Spara</Button>
                    </Box>
                </ColumnBox>
            </RowBox>
        </BaseModal>
    );
}