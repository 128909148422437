// Utility function
export function addSpaceAroundCharacter(value: string, character: string): string {
    // Escape special characters in the character input for use in a regular expression
    const escapedCharacter = character.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(escapedCharacter, 'g');
    return value.replace(regex, ` ${character} `);
}

// Constant array of months in Swedish
const months = [
    "Jan",  // Januari
    "Feb",  // Februari
    "Mar",  // Mars
    "Apr",  // April
    "Maj",  // Maj
    "Jun",  // Juni
    "Jul",  // Juli
    "Aug",  // Augusti
    "Sep",  // September
    "Okt",  // Oktober
    "Nov",  // November
    "Dec",  // December
];

// Exporting structured data
export const monthsData = [{ scaleType: 'band', data: months }];
export const lineData = [12, 18, 16, 20, 28, 12, 12, 8, 1, 5, 14, 51];

// Generate random orderNumber
export function generateRandomNumberWithDash() {
    // Generate two random numbers
    const part1 = Math.floor(Math.random() * 1000); 
    const part2 = Math.floor(Math.random() * 1000);

    return `${part1}-${part2}`;
}

// Merge nested fields, used for multiline forms
export function mergeFieldsIntoTarget<T extends Record<string, any>>(
    obj: T,
    targetField: keyof T
): T {
    // Check if the target field exists and is an object
    if (obj[targetField] && typeof obj[targetField] === 'object') {
        // Loop through the main object fields
        for (const key in obj) {
            // Skip the target field itself
            if (key === targetField) continue;

            // If the key exists in the target field, update its value
            if (obj[targetField].hasOwnProperty(key)) {
                (obj[targetField] as Record<string, any>)[key] = obj[key];
            }
        }
    }
    return obj[targetField];
}
