import React, { useEffect, useState } from 'react';
import { BaseModal, BaseModalProps } from '../../components/modals/BaseModal';
import { Box, Button, useTheme } from '@mui/material';
import { ColumnBox, RowBox } from '../../styles/commonStyles';
import { ProgressStep } from '../../components/common/ProgressStep';
import { CardLayout } from '../../components/layouts/CardLayout';
import { TextWrapper } from '../../components/common/TextWrapper';
import { AccessTime, CalendarMonth, Call, Home, Person, Pin, Work } from '@mui/icons-material';
import { TextField } from '../../components/common/TextField';
import { Dropdown } from '../../components/common/Dropdown';
import { TagField } from '../../components/common/TagField';
import { Employee } from '../../models/Employee';
import useAPI from '../../hooks/useAPI';

export const EmployeeModal : React.FC<BaseModalProps> = ({name, path, title}) => {
    const [steps, setSteps] = useState<Map<string, boolean>>(new Map([
        ['Skapa medararbetare', false],
        ['Personuppgifter', false],
        ['Datum & Tider', false],
        ['Attribut', false],
    ]));
    const [employee, setEmployee] = useState<Employee>({
        name:'',
        pin:'',
        address:'',
        tel:'',
        employmentDate:'',
        startTime:'',
        endTime:'',
        email:'',
        absence:100,
        employmentType:'',
        attributes:[],
    });
    // Use Api to register employee
    const { data, isLoading, error, callAPI} = useAPI<Employee>("/employee",{ method: 'POST', data: employee });

    // theme reference
    const theme = useTheme();
    const updateStep = (stepName: string, value: boolean) => {
        setSteps((prevSteps) => {
            const updatedSteps = new Map(prevSteps);
            updatedSteps.set(stepName, value); 
            return updatedSteps; 
        });
    };
    // Handle on change fields
    const handleOnChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        // Get var name and value
        const { name, value } = e.target;
        // Update employee
        setEmployee((prev) => ({
            ...prev,
            [name as string]: value,
        }));
    }
    // Handle submit form
    const handleSubmit = () => {
        callAPI();
    }
    // Handle on tag change
    const handelOnTagChange = (tags : string[]) => {
        setEmployee((prev) => ({
            ...prev,
            attributes: tags,
        }));
    }
    // Update Steps based on employees properties
    useEffect(() => {
         // Update Progress Step
         updateStep("Skapa medararbetare", employee.name.length > 0 && employee.pin.length > 0);
         updateStep("Personuppgifter", employee.address.length > 0 && employee.tel.length > 0);
         updateStep("Datum & Tider",employee.employmentType.length > 0 && employee.employmentDate.length > 0 && employee.startTime.length > 0 && employee.endTime.length > 0);
         updateStep("Attribut", employee.attributes.length > 0);
    }, [employee]);

    // When data is loaded close modal and refersh page
    useEffect(() => {
        // Print out employee when is updated
        if(data){ // success
            // Refresh to fetch newly registered employees
            window.location.reload();
        }
    }, [data])

    return(
        <BaseModal
            name={name}
            path={path}
            title={title}
        >
            <RowBox>
                {/* Informaiton Side */}
                <ColumnBox sx={{width:'30%'}}>
                    <ProgressStep steps={steps}/>
                </ColumnBox>
                {/* Content Side */}
                <ColumnBox sx={{width:'70%'}}>
                    <CardLayout title="Skapa medarbetare" index={1} description="Vad heter medarbetaren?">
                        <TextWrapper label='Namn' labelPosition='top'>
                            <TextField name='name' placeholder='Enter name' onChange={handleOnChange}  icon={<Person sx={{color:'gray', marginRight:'5px'}}/>} iconAlign='left'/>
                        </TextWrapper>
                        <TextWrapper label='Personal Number' labelPosition='top'>
                            <TextField name='pin' onChange={handleOnChange} placeholder='Enter pin'  icon={<Pin sx={{color:'gray', marginRight:'5px'}}/>} iconAlign='left'/>
                        </TextWrapper>
                    </CardLayout>
                    <CardLayout title="Personuppgifter" index={2} description="Ange uppgifter om medarbetaren?">
                        <TextWrapper label='Adress' labelPosition='top'>
                            <TextField name='address' onChange={handleOnChange} placeholder='Enter address'  icon={<Home sx={{color:'gray', marginRight:'5px'}}/>} iconAlign='left'/>
                        </TextWrapper>
                        <TextWrapper label='Telefon nr.' labelPosition='top'>
                            <TextField name='tel' onChange={handleOnChange} placeholder='Enter telephone'  icon={<Call sx={{color:'gray', marginRight:'5px'}}/>} iconAlign='left'/>
                        </TextWrapper>
                    </CardLayout>
                    <CardLayout title="Daturm & Tider" index={3} description="När ska medarbetaren arbeta?">
                        <TextWrapper label='Anställningsform' labelPosition='top'>
                            <Dropdown name='employmentType' onChange={handleOnChange} title='Välja Anställningsform' icon={<Work sx={{paddingLeft:'5px', }}/>} data={['Heltid', 'Deltid']}/>
                        </TextWrapper>
                        <RowBox>
                            <TextWrapper label='Anställningsdatum' labelPosition='top'>
                                <Dropdown name='employmentDate' onChange={handleOnChange} title='Välja Datum' icon={<CalendarMonth sx={{paddingLeft:'5px', }}/>} data={['2024-01-01', '2024-02-01','2022-06-01']}/>
                            </TextWrapper>
                            <TextWrapper label='Starttid' labelPosition='top'>
                                <Dropdown name='startTime' onChange={handleOnChange} title='Välja tid' icon={<AccessTime sx={{paddingLeft:'5px', }}/>} data={['07:00', '08:00','09:00']}/>
                            </TextWrapper>
                            <TextWrapper label='Sluttid' labelPosition='top'>
                               <Dropdown name='endTime' onChange={handleOnChange} title='Välja tid' icon={<AccessTime sx={{paddingLeft:'5px', }}/>} data={['16:00', '17:00','18:00']}/>
                            </TextWrapper>
                        </RowBox>
                    </CardLayout>
                    <CardLayout title="Attribut" index={4} description="Speciella saker">
                        <TextWrapper label='Spårk' labelPosition='top'>
                            <TagField title="Enter languages" tags={employee.attributes} onChange={handelOnTagChange}/>
                        </TextWrapper>
                    </CardLayout>
                    <Box sx={{display:'flex', flexFlow:'row-reverse', padding:'15px'}}>
                        <Button onClick={handleSubmit} disabled={isLoading} variant='contained'>{isLoading ? "Sparande..." : "Spara"}</Button>
                    </Box>
                </ColumnBox>
            </RowBox>
        </BaseModal>
    );
}