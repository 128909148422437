import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

interface ProgressStepProps {
    steps : Map<string,boolean>,
}

export const ProgressStep : React.FC<ProgressStepProps> = ({steps}) => {
    const theme = useTheme();
    return(
        <ProgressContainer>
            {Array.from(steps).map(([key, value], index) =>(
                <Step key={index}>
                    <BoxRow>
                        <Typography fontWeight={'bold'} fontSize={'14px'}>Steg {index + 1} </Typography>
                        <Typography fontStyle='italic' fontSize={'14px'}> - {key}</Typography>
                    </BoxRow>
                    <BoxRow>
                        {value ? <CheckCircleIcon sx={{color:theme.palette.customColors.color1}}/> : <RadioButtonUncheckedIcon  sx={{color:theme.palette.customColors.color1}}/>}
                    </BoxRow>
                </Step>
            ))}
        </ProgressContainer>
    );
}
const BoxRow = styled(Box)(({theme}) =>({
    display:'flex',
    flexFlow:'row',
    alignItems:'center',
}));

const Step = styled(Box)(({theme}) => ({
    padding:'5px',
    display:'flex',
    flexFlow:'row',
    justifyContent:'space-between',
    alignItems:'center'
}));

const ProgressContainer = styled(Box)(({theme})=>({
    background:theme.palette.customColors.color2,
    borderRadius:10,
    padding:'20px',
}));


