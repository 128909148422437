import { Container, Grid2, Typography } from "@mui/material";
import React from "react";
import PageLayout from "../../components/layouts/PageLayout";
import { TableWidget } from "../../components/widgets/TableWidget";

const Insights : React.FC = () => {
    // Handle Edit Button
    const handleOnEdit = () : void => {
        console.log("Edit");
    }
    return (
        <PageLayout 
            title="Insikter"
            onEdit={handleOnEdit}>
        
        </PageLayout>
    );
}

export default Insights;