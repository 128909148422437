import { Search } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import micronIq from "../../assets/images/micronIq_icon.png";
import { addSpaceAroundCharacter } from "../../utils/Utils";

interface HeaderProps {
    path: string;
};

const Header : React.FC<HeaderProps> = ({path}) => {
    const [isSearchActive, setIsSearchActive] = useState<boolean>(false);
    // handle on click search
    const handleOnClickSearch = () => {
        setIsSearchActive(!isSearchActive);
    }
    return (
        <Box sx={{display:'flex', flexFlow:'column', width:'100%'}}>
            {/* Search Bar section */}
            <Box sx={{display:'flex', flexFlow:'row', width:'100%'}}>
                <Box component="img"  sx={{ height: 40, width: 40, borderRadius:50}} src={micronIq}/>
                <TextField
                    id="input-with-icon-textfield"
                    label=""
                    sx={{
                        marginLeft:'20px',
                        bgcolor:'white',
                        borderRadius:50,
                        transition:'width 0.3s',
                        width: isSearchActive ? "100%" : "40px",
                    }}
                    slotProps={{
                    input: {
                        disableUnderline:true,
                        startAdornment: (
                            <IconButton onClick={handleOnClickSearch} sx={{height:40, width:40, bgcolor:'white'}}>
                                <Search/>
                            </IconButton>
                        ),
                    },
                    }}
                    variant="standard"/>
            </Box>
            {/* Path section */}
            <Typography color='grey' fontSize='14px' padding="20px 0px 20px 0px">{addSpaceAroundCharacter(path,"/")}</Typography>
        </Box>
    );
}

export default Header