import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Divider, Typography } from '@mui/material';
import { ColumnBox, RowBox } from '../../styles/commonStyles';
import { WidgetLayout, WidgetProps } from '../layouts/WidgetLayout';

interface ReminderProps extends WidgetProps {
    title: string;
    description: string;
    when: string;
    hideHeader?: boolean;
    icon?: React.ReactNode;
}
export const Reminder : React.FC<ReminderProps> = ({title, description, when, size, icon, hideHeader = true}) => {
    const theme = useTheme();
    return(
        <WidgetLayout size={size} hideHeader={hideHeader} disablePadding={true}>
            <ReminderContainer>
                <RowBox sx={{justifyContent:'space-between', padding:'10px'}}>
                    <Typography color='white' fontWeight='bold'>{title}</Typography>
                    {icon}
                </RowBox>
                <Divider sx={{ width: '97%', margin: '0 2%', bgcolor:theme.palette.customColors.color3}} />
                <Typography fontWeight='bold' color='white' fontSize='14px' padding='10px'>{description}</Typography>
                <Typography fontSize='12px'  color='white' padding='10px'>{when}</Typography>
            </ReminderContainer>
        </WidgetLayout>
    );
}

const ReminderContainer = styled(Box)(({theme})=>({
    borderRadius:'8px',
    padding:'10px',
    background:theme.palette.customColors.color1,
    display:'flex',
    flexFlow:'column',
    '&:hover': {
        cursor:'pointer',
    },
    '&:active':{
        background:'grey',
    }
    
}));
