import { styled } from '@mui/material/styles';
import { Box, Container, Fade, Icon, IconButton, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import micronIq from "../../assets/images/micronIq_icon.png";
import { Close } from '@mui/icons-material';
import { addSpaceAroundCharacter } from '../../utils/Utils';
import { useModal } from '../../contexts/ModalContext';

export interface BaseModalProps {
    title?: string;
    name: string;
    path: string;
    children?: React.ReactNode;
    extras?: React.ReactNode[];
}
export const BaseModal : React.FC<BaseModalProps> = ({title, name, path, children, extras}) => {
    // Use modal context
    const { modalState, close } = useModal();
    // Handle close modal
    const handleOnClose = () => {
        close(name);
    }
    return (
        <ModalContainer 
            open={!!modalState[name] ? modalState[name] : false} 
        >
            <ModalView>
                <ModalHeader>
                    <FlexRowBox>
                        <ModalHeaderIcon src={micronIq}/>
                        <PathText>{addSpaceAroundCharacter(path , "/")}</PathText>
                    </FlexRowBox>
                    <FlexRowBox>
                        <IconButton onClick={handleOnClose}>
                            <Close sx={{width:40, height:40}}/>
                        </IconButton>
                    </FlexRowBox>
                </ModalHeader>
                <ModelContent>
                    <ModalHeader>
                        <FlexRowBox>
                            <ModalTitle>{title}</ModalTitle>
                        </FlexRowBox>
                        <FlexRowBox>
                            {extras?.map((item, index)=>(
                                <Box key={index} sx={{marginRight:'10px'}}>
                                    {item}
                                </Box>
                            ))}
                        </FlexRowBox>
                    </ModalHeader>
                    <ModelContent>
                        {children}
                    </ModelContent>
                </ModelContent>
            </ModalView>
        </ModalContainer>
    );
}
const ModalContainer = styled(Modal)(({theme})=>({
    overflow:'scroll',
}));

const ModelContent = styled(Container)(({theme}) => ({
    paddingTop:30,
}));

const ModalTitle = styled(Typography)(({theme}) =>({
    fontWeight: 'bold',
    fontStyle: 'italic',
    color:theme.palette.customColors.color1,
    fontSize:50,

}));

const PathText = styled(Typography)(({theme})=>({
    fontWeight:'bold',
    color:'gray',
    fontSize:'14px',
}));

const FlexRowBox = styled(Box)(({theme})=>({
    display:'flex',
    flexFlow:'row',
    alignItems:'center',
}));

const ModalView = styled(Box)(({theme})=>({
    background: theme.palette.background.default,
    minHeight:'100vh',
    display:'flex',
    flexFlow:'column',
    padding:"30px",
}));

const ModalHeader = styled(Box)(({theme}) => ({
    display:'flex',
    flexFlow:'row',
    justifyContent:'space-between',
    alignItems:'center',
}));

const ModalHeaderIcon = styled('img')(({theme})=>({
    borderRadius:50,
    height:40,
    width:40,
    marginRight:10,
}));