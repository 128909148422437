import React, { useState, KeyboardEvent, ChangeEvent } from "react";
import { TextField, Chip, Box, styled} from "@mui/material";;

interface TagProps {
    tags?: string[];
    title?: string,
    sx?: object;
    name?: string;
    onChange: (tag : string[], name? : string) => void;
}

export const TagField: React.FC<TagProps> = ({ sx, onChange, title = "enter", tags = [], name}) => {
    const [tag, setTag] = useState<string[]>(tags); // State for tags
    const [inputValue, setInputValue] = useState<string>(""); // State for input value

    // Handle the Enter key event
    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter" && inputValue.trim() !== "") {
            if (!tag.includes(inputValue.trim())) {
                const updatedTags = [...tag, inputValue.trim()];
                setTag(updatedTags);
                onChange(updatedTags, name);
            }
            setInputValue(""); // Clear the input
        }
    };

    // Handle the delete event for tags
    const handleDelete = (tagToDelete: string) => {
        const updatedTags = tag.filter((value) => value !== tagToDelete);
        setTag(updatedTags);
        onChange(updatedTags, name);
    };

    // Handle input change
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    return (
        <TagContainer sx={sx}>
            {tag.map((tag, index) => (
            <Chip
                key={index}
                label={tag}
                onDelete={() => handleDelete(tag)}
                color="primary"
                sx={{ marginRight: 1, marginBottom: 1 }}
            />
            ))}

            <TextField
            placeholder={title}
            variant="standard"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            sx={{ flexGrow: 1, width:'100%'}}
            slotProps={{
                input:{
                    disableUnderline:true,
                }
            }}
            />
        </TagContainer>
    );
};

const TagContainer = styled(Box)(({theme})=>({
    background: theme.palette.background.default,
    borderRadius:'8px',
    width:'100%',
    padding: '8px 10px 8px 10px'
}));