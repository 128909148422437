import React from 'react';
import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Dashboard from './pages/dashboard/Dashboard';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Functional, Resource, Utils, Settings } from './configs/menuConfig';
import { theme } from './configs/theme';
import { ModalProvder } from './contexts/ModalContext';
import { ContractModal } from './pages/contracts/ContractModal';
import { OrderModal } from './pages/order/OrderModal';
import { EmployeeModal } from './pages/resources/EmployeeModal';
import { VehiclesModal } from './pages/resources/VehiclesModal';
import { CustomerModal } from './pages/customers/CustomerModal';
import { CaseModal } from './pages/case/CaseModal';
import { AlertProvider } from './contexts/AlertContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import { ContractActionModal } from './pages/contracts/ContractActionModal';

const App : React.FC = () => {
  // use Modal
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AlertProvider>
        <ModalProvder>
          <ThemeProvider theme={theme}>
            <CssBaseline />  
            <BrowserRouter>
            {/* Global Modals */}
            <OrderModal name="OrderModal" title="Skapa en ny order" path="Startsida/Order/Skapa Order" />
            <ContractModal name="OfferModal" title="Skapa en ny offert" path="Startsida/Offert/Skapa Offert"/>
            <ContractModal name="ProjectModal" title="Skapa ett nytt projekt" path="Startsida/Projekt/Skapa Projekt" />
            <EmployeeModal name="EmployeeModal" title="Skapa en ny medarbetare" path="Startsida/Resource/Medarbetare/Skapa medarbetare" />    
            <ContractActionModal name="ContractActionModal" title="Offertförslag" path="Startsida/Offer/Status" />      
            <VehiclesModal name="VehiclesModals" title="Skapa ett nytt fordon" path="Startsida/Resurser/Fordon/Skapa fordon" />
            <CustomerModal name="CustomerModal" title="Skapa en ny kund" path="Startsida/Kunder/Skapa Kund" />
            <CaseModal name="CaseModal" title="Skapa ett nytt ärende" path="Startsida/Ärendehantering/Skapa Ärende"/>
            {/* Routes that are defined in menuConfig file */}
              <Routes>
                <Route path="/" element={<Dashboard />}>
                  {Functional.concat(Resource, Utils, Settings).map((item, index)=>(
                    <React.Fragment key={index}>
                      <Route path={item.to} element={item.element} />
                      {item.submenu && item.submenu.length > 0 && (
                          item.submenu.map((subItem, subIndex) => (
                              <Route key={subIndex} path={subItem.to} element={subItem.element} />
                          ))
                      )}
                    </React.Fragment>
                  ))}
                </Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </ModalProvder>
      </AlertProvider>
    </LocalizationProvider>
  );
}

export default App;
