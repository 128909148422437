import React, { useEffect, useState } from "react"
import { BaseModal, BaseModalProps } from "../../components/modals/BaseModal"
import { useNavigate, useSearchParams } from "react-router-dom";
import useAPI from "../../hooks/useAPI";
import { Contract } from "../../models/Contract";
import { useAlert } from "../../contexts/AlertContext";
import { Box, Button, Container } from "@mui/material";
import { CardLayout } from "../../components/layouts/CardLayout";
import { RowBox } from "../../styles/commonStyles";
import { TextWrapper } from "../../components/common/TextWrapper";
import { TextField } from "../../components/common/TextField";
import { useModal } from "../../contexts/ModalContext";
import { stat } from "fs";
export const ContractActionModal : React.FC<BaseModalProps> = ({path, name, title}) => {
    // Modal state
    const { modalState, close } = useModal();
    // Use navigation
    const navigate = useNavigate();
    // Contract object
    const [contract, setContract] = useState<Contract | null>(null);
    // Use Alert notification
    const  { alert } = useAlert();
    // Use search params to get id from query
    const [params] = useSearchParams();
    // Get id from params
    const contractId = params.get('id');
    // Fetch contract by id from api
    const { data, error, isLoading, callAPI} = useAPI<Contract>(`/contract/${contractId}?relations=${['customer']}`)
    // submit action to api
    const {data: response, error: errorResponse, callAPI : submitToAPI} = useAPI<Contract>('/contract/', {method:'PUT'});
    // Poplate view when contract is loaded
    useEffect(()=>{
        // set contract
        if(!modalState[name]) return; // skip setting when modal is closed

        if(data){
            setContract(data);
        }
        else{
            alert("error", "No contract found with this id!")
        }
        
    },[data]);
    // Request to fetch contract on component mount
    useEffect(()=>{
        if(modalState[name]){
            callAPI(); // fetch contract  
        }
    },[modalState]);

    // Handle onAccept
    const onAccept = () => {
        setStatus('Godkänd');
    }

    // Handle onDecline
    const onDecline = () => {
        setStatus('Avvisad');
    }

    // Set contract status
    const setStatus = (status: 'Godkänd' | 'Avvisad') => {
        const body = {...contract, status};
        console.log(body);
        // Submit to API
        submitToAPI(body);
    }
    // Poplate view when contract is loaded
    useEffect(()=>{
        // set contract
        if(!modalState[name]) return; // skip setting when modal is closed

        if(response){
            alert("success", "Contract accepted successfully!");
        }
        else{
            alert("error", "Contract declined!")
        }
        // navigate back to offer and close modal
        close(name);
        navigate('/offert');
        window.location.reload();
    },[response]);

    return(
        <BaseModal
            path={path}
            name={name}
            title={`${title} #${contractId}`}
        >
            <Container>
                <CardLayout title="Offer Information" index={1}>
                    <TextWrapper label='Customer' sx={{padding:'20px',width:'100%'}} labelPosition='top'>       
                        <TextField readOnly={true} value={`${contract?.customer?.identityNumber} | ${contract?.customer?.name}`}/>
                    </TextWrapper>
                    <TextWrapper label='Adress' sx={{padding:'20px',width:'100%'}} labelPosition='top'>       
                        <TextField readOnly={true} value={`${contract?.address ?? ''} ${contract?.postNumber ?? ''} ${contract?.city ?? ''}`}/>
                    </TextWrapper>
                    <TextWrapper label='Created On' sx={{padding:'20px',width:'100%'}} labelPosition='top'>       
                        <TextField readOnly={true} value={`${contract?.createdOn ?? ''}`}/>
                    </TextWrapper>
                </CardLayout>
                <Box sx={{display:'flex', flexFlow:'row-reverse', padding:'15px'}}>
                    <Button onClick={onAccept} variant='contained'>Acceptera</Button>
                    <Button onClick={onDecline} sx={{bgcolor:'#C1666B', marginRight:'10px'}}variant='contained'>Avvisad</Button>
                </Box>
            </Container>
        </BaseModal>
    );
}