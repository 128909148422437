import { Box, Divider, IconButton, Typography } from '@mui/material';
import React from 'react';
import { ColumnBox, RowBox } from '../../styles/commonStyles';
import { styled } from '@mui/material/styles';
import { Add } from '@mui/icons-material';

interface CardProps {
    index?: number,
    title: string,
    description?: string;
    sideComponent?: React.ReactNode;
    children: React.ReactNode;
}

export const CardLayout : React.FC<CardProps> = ({index, title, description, children, sideComponent}) => {
    return(
        <CardContainer>
            {/* Title Section */}
            <RowBox sx={{ width:'100%',justifyContent:'space-between'}}>
                <RowBox sx={{padding:'15px', alignItems:'center'}}>
                    <IndexContainer>
                    <Typography fontSize={'14px'}>{index}</Typography>
                    </IndexContainer>
                    <ColumnBox sx={{paddingLeft:'15px'}}>
                        <Typography fontWeight={'bold'} fontSize={"15px"} lineHeight={'15px'}>
                            {title}
                        </Typography>
                        <Typography fontStyle={'italic'} fontSize={'13px'} color='gray'>
                            {description}
                        </Typography>
                    </ColumnBox>
                </RowBox>
                <RowBox sx={{alignItems:'center', paddingRight:'10px'}}>
                       {sideComponent}
                </RowBox>
            </RowBox>
            <Divider/>
            {/* Content Section */}
            <ColumnBox sx={{padding:'15px'}}>
                {children}
            </ColumnBox>
        </CardContainer>
    );
}

const IndexContainer = styled(Box)(({theme})=>({
    display:'flex',
    background: theme.palette.customColors.color2,
    padding:'10px',
    width:'30px',
    height:'30px',
    justifyContent:'center',
    alignItems:'center',
    borderRadius:'50%',
}));

const CardContainer = styled(Box)(({theme})=>({
    background:'white',
    borderRadius:'10px',
    margin:'0 15px 15px 15px'
}));