import { Box, Button, Checkbox, Typography } from "@mui/material";
import React, { useState } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import { SearchSelect } from "../../components/common/SearchSelect";
import { Column, TableWidget } from "../../components/widgets/TableWidget";
import { StatWidget } from "../../components/widgets/StatWidget";
import { Search } from "@mui/icons-material";
import { lineData, monthsData } from "../../utils/Utils";

interface ProductTable {
    id: number;
    name: string;
    unit: string;
    price: string; 
}

const productColumn: Column[] = [
    { header: 'Nr.', accessor: 'id', align: 'left' },
    { header: 'Produkt', accessor: 'name', align: 'left' },
    { header: 'Enhet', accessor: 'unit', align: 'left' },
    { header: 'Pris', accessor: 'price', align: 'right' },
];

const productData : ProductTable[] = [
    {
        id:1,
        name:'Toalettpapper',
        unit:'Bal',
        price: "530 kr"
    },
    {
        id:1,
        name:'Toalettpapper',
        unit:'Bal',
        price: "530 kr"
    },
    {
        id:1,
        name:'Toalettpapper',
        unit:'Bal',
        price: "530 kr"
    },
    {
        id:1,
        name:'Toalettpapper',
        unit:'Bal',
        price: "530 kr"
    },

];

const Products : React.FC = () =>{
    // handle OnEdit
    const handleOnEdit = () => {
    }
    return(
        <PageLayout
            title="Produkter"
            onEdit={handleOnEdit}
            extras={[    
                <SearchSelect inputStyle={{background:'white'}} placeholder="Sök" icon={<Search/>} iconAlign="right"/>   
            ]}
        >
            <StatWidget title="Sålda produkter" variant="bar" xAxis={monthsData} size={12} data={lineData}/>
            <TableWidget title="Produkter Lista" size={12} data={productData} columns={productColumn}/>
        </PageLayout>
    );
} 

export default Products;