import { useEffect, useState } from "react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export default function useAPI<T>(url: string, options?: AxiosRequestConfig){
    const host = process.env.REACT_APP_API_URL;
    const [data, setData] = useState<T | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState(null);

    const callAPI = async (body? : object) => {
        setIsLoading(true); // Set is loading true
        try {
            const response : AxiosResponse<T> = await axios(host + url, body && options ? {...options, data: body} : options);
            // Set Data
            setData(response.data);
        } catch(error : any){
            // Set error
            setError(error);
        } finally {
            // Set Loading to false
            setIsLoading(false);
        }
    };

    return { data, isLoading, error, callAPI};
} 