import { Box, Button, Checkbox, Typography } from "@mui/material";
import React, { useState } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import { SearchSelect } from "../../components/common/SearchSelect";
import { Column, TableWidget } from "../../components/widgets/TableWidget";
import { StatWidget } from "../../components/widgets/StatWidget";
import { Search } from "@mui/icons-material";
import { lineData, monthsData } from "../../utils/Utils";

interface ServicesTable {
    id: number;
    name: string;
    unit: string;
    price: string; 
}

const servicesColumn: Column[] = [
    { header: 'Nr.', accessor: 'id', align: 'left' },
    { header: 'Produkt', accessor: 'name', align: 'left' },
    { header: 'Enhet', accessor: 'unit', align: 'left' },
    { header: 'Pris', accessor: 'price', align: 'right' },
];

const servicesData : ServicesTable[] = [
    {
        id:1,
        name:'Fönsterputs',
        unit:'Timpris',
        price: "430 kr"
    },
    {
        id:1,
        name:'Fönsterputs',
        unit:'Timpris',
        price: "430 kr"
    },
    {
        id:1,
        name:'Fönsterputs',
        unit:'Timpris',
        price: "430 kr"
    },
    {
        id:1,
        name:'Fönsterputs',
        unit:'Timpris',
        price: "430 kr"
    },
];


const Services : React.FC = () =>{
    // handle OnEdit
    const handleOnEdit = () => {
    }

    return(
        <PageLayout
            title="Tjänster"
            onEdit={handleOnEdit}
            extras={[    
                <SearchSelect inputStyle={{background:'white'}}placeholder="Sök" icon={<Search/>} iconAlign="right"/>   
            ]}
        >
            <StatWidget title="Sålda tjänster" variant="bar" size={12} xAxis={monthsData} data={lineData}/>
            <TableWidget title="Tjänster Lista" size={12} data={servicesData} columns={servicesColumn}/>
        </PageLayout>
    );
} 

export default Services;