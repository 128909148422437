import React from 'react';
import { ColumnBox } from '../../styles/commonStyles';
import { styled } from '@mui/material/styles';
import { Box, Grid2, Typography } from '@mui/material';

export interface WidgetProps {
    title?: string;
    children?: React.ReactNode;
    extras?: React.ReactNode[];
    size?: number;
    disablePadding?: boolean;
    hideHeader?: boolean;
}

export const WidgetLayout : React.FC<WidgetProps> = ({title, children, extras, size, disablePadding, hideHeader}) => {
    return (
        <WidgetContainer size={size}>
            {!hideHeader && <WidgetHeader>
                <WidgetTitle>{title}</WidgetTitle>
                <WidgetExtras>
                    {extras?.map((item, index)=>(
                        <Box key={index} sx={{marginRight:'10px'}}>
                            {item}
                        </Box>
                    ))}
                </WidgetExtras>
            </WidgetHeader>}
            <WidgetContent sx={{padding:disablePadding ? '0px' : '20px'}}>
                {children}
            </WidgetContent>
        </WidgetContainer>
    );
}

const WidgetContent= styled(Box)(({theme}) =>({
    background:theme.palette.customColors.color3,
    borderEndEndRadius:'8px',
    borderBottomLeftRadius:'8px',
}));

const WidgetTitle = styled(Typography)(({theme})=>({
    fontWeight:'bold',
    color:theme.palette.customColors.color1,
    padding:'15px',
    fontSize:'18px',
}));

const WidgetHeader = styled(Box)(({theme})=>({
    display:'flex',
    alignItems:'center',
    flexFlow:'row',
}));

const WidgetExtras = styled(Box)(({theme})=>({
    display:'flex',
    flexFlow:'row-reverse'
}));

const WidgetContainer = styled(Grid2)(({theme})=>({
    display:'flex',
    flexFlow:'column',
    borderRadius:'8px',
    background:theme.palette.background.paper,
}));