import { FormatListBulleted } from '@mui/icons-material';
import { MenuItem, Select, Typography, useTheme, styled, Box} from '@mui/material';
import React from 'react';

interface DropdownProps {
    title?: string;
    icon?: any;
    data?: any [];
    name?: string;
    onChange?: (value : any) => void;
    sx?: object;
}
export const Dropdown : React.FC<DropdownProps> = ({title, icon, data, onChange, sx, name}) => {
    const theme = useTheme();
    return(
        <SelectContainer sx={sx}>
            {icon}
            <SelectStyled
                onChange={onChange}
                displayEmpty
                name={name}
                renderValue={(value: any) => {
                    return value ? value : <Typography>{title}</Typography>;
                }}
            >
                {data?.map((item, index)=>(
                    <MenuItem key={index} value={item}>
                        {item}
                    </MenuItem>
                ))}
            </SelectStyled>
        </SelectContainer>
    );
}

const SelectContainer = styled(Box)(({theme}) =>({
    background: theme.palette.background.default,
    width:'100%',
    borderRadius:'8px',
    padding:'10px',
    display:'flex',
    flexFlow:'row',
    alignItems:'center',
}));

const SelectStyled = styled(Select)(({theme})=>({
    width:'100%',
    height:'30px',
    boxShadow:'none',
    ".MuiOutlinedInput-notchedOutline": { 
        border: 'none', 
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: 'none'
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        border: 'none',
    },
  
}));
