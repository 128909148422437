import { Box, styled, Typography } from '@mui/material';
import React from 'react';

export interface TextWrapperProps {
  children: React.ReactNode;
  label?: string;
  labelPosition?: 'top' | 'left' | 'right' | 'bottom';
  sx?: object; 
  labelSx?: object; 
}

export const TextWrapper: React.FC<TextWrapperProps> = ({ label, labelPosition = 'top', children, sx = { padding:'20px' }, 
  labelSx = {
    fontWeight:'bold', 
    lineHeight:'5px', 
    paddingLeft:'2px', 
    fontSize:'14px'
  }}) => {
  return (
    <TextContainer labelPosition={labelPosition} sx={sx}>
        {label && <Typography sx={labelSx}>{label}</Typography>}
      <ContentContainer>{children}</ContentContainer>
    </TextContainer>
  );
};

// Styled container for the entire wrapper
const TextContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'labelPosition' })<{labelPosition: string;}>(({ theme, labelPosition }) => ({
    display: 'flex',
    flexDirection: getFlexDirection(labelPosition),
    alignItems: labelPosition === 'left' || labelPosition === 'right' ? 'center' : undefined,
    gap: theme.spacing(1),
}));

// Helper function to determine flex direction based on label position
const getFlexDirection = (labelPosition: string) => {
    switch (labelPosition) {
      case 'top':
        return 'column';
      case 'bottom':
        return 'column-reverse';
      case 'left':
        return 'row';
      case 'right':
        return 'row-reverse';
      default:
        return 'column';
    }
};
// Styled container for children
const ContentContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
});