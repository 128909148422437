import React, { useState } from 'react';
import { Drawer,Divider, Box, Typography, IconButton, useTheme } from '@mui/material';
import MenuList from '../../components/navigation/MenuList';
import { Functional, Resource, Settings, Utils } from '../../configs/menuConfig';
import { Close } from '@mui/icons-material';
import Header from '../../components/common/Header'; 
import { MenuItem } from '../../configs/menuConfig';
import {Outlet, useNavigate } from 'react-router-dom';

const drawerCssProps = {
    openWidth: 240,
    closedWidth: 60,
};

const Dashboard : React.FC = () => {
    const [activeItem, setActiveItem] = useState<MenuItem>(Functional[0].submenu ? Functional[0].submenu[0] : Functional[0]);
    const [isOpen, setIsOpen] = useState<boolean>(true);    
    const navigate = useNavigate();
    const theme = useTheme();
    // Handle toggle drawer
    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    }; 
    // Handle itemClick
    const handleItemClick = (item: MenuItem) => {
        setActiveItem(!item.submenu ? item : item.submenu[0]);
        setIsOpen(true); // automatically expand drawer
        navigate(item.to);
    };
    return (
        <>
        <Drawer 
            anchor="left"
            open={true}
            variant="persistent"
            sx={{
                width: isOpen ? drawerCssProps.openWidth : drawerCssProps.closedWidth,
                transition: '0.3s',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    border:'none',
                    width: isOpen ? drawerCssProps.openWidth : drawerCssProps.closedWidth,
                    overflowX:'hidden',
                    transition: '0.3s', 
                    '&::-webkit-scrollbar': {
                        display: 'none', // For Chrome, Safari, and Edge to hide the scrollbar
                    },
                },
            }}
            ModalProps={{
                keepMounted:true,
                hideBackdrop:true,
            }}>
            {/* Profile Section */}
            <Box sx={{display:'flex', flexFlow:'row', width:'100%', minWidth:drawerCssProps.openWidth, margin:'10px 0px 10px 0px'}}>
                {/* Profile Picture */}
                <Box component="img" onClick={toggleDrawer} sx={{ height: 40, width: 40, borderWidth:"2px", border:'solid', padding:"1px", borderRadius:50, borderColor:theme.palette.customColors.color1, margin:"10px", '&:hover':{cursor:'pointer'}}} alt="The house from the offer." src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"/>
                {/* Profile Data */}
                <Box sx={{display:'flex', flexFlow:'column', marginTop:'10px'}}>
                    <Typography sx={{fontWeight:'bold'}}>Alex</Typography>
                    <Typography sx={{fontSize:'12px'}}>Dygnet Runt</Typography>
                </Box>
                {/* Toggle Button */}
                <IconButton onClick={toggleDrawer} sx={{height:50, width:50, margin:'10px 0px 0px 50px'}}>
                    <Close/>
                </IconButton>
            </Box>
            <MenuList menuItem={Functional} activeItem={activeItem} isOpen={isOpen} onClickItem={handleItemClick}/>
            <Divider sx={{ width: '90%', margin: '0 5%' }} />
            <MenuList menuItem={Resource} activeItem={activeItem} isOpen={isOpen} onClickItem={handleItemClick}/>
            <Divider sx={{ width: '90%', margin: '0 5%' }} />
            <MenuList menuItem={Utils} activeItem={activeItem} isOpen={isOpen} onClickItem={handleItemClick}/>
            <Divider sx={{ width: '90%', margin: '0 5%' }} />
            <MenuList menuItem={Settings} activeItem={activeItem} isOpen={isOpen} onClickItem={handleItemClick}/>
        </Drawer>
        {/* Main Content View */}
        <Box sx={{display:'flex', width: isOpen ? `calc(100% - ${drawerCssProps.openWidth}px)`: `calc(100% - ${drawerCssProps.closedWidth}px)`, flexFlow:'column', padding:"20px 50px 20px 50px", transition:'margin 0.3s', marginLeft: isOpen ? `${drawerCssProps.openWidth}px` : `${drawerCssProps.closedWidth}px`,}}>
            <Header path={`Startsida${activeItem.to}`}/>
            <Outlet/>
        </Box>
        </>
    );
};

export default Dashboard;