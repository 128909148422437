import { Autocomplete, SxProps, TextField } from '@mui/material';
import React from 'react';
import { theme } from '../../configs/theme';

interface SearchSelectProps {
    value?: string | string[];
    onChange?: (event: React.ChangeEvent<any>) => void;
    onFocus?: () => void;
    name?: string;
    placeholder?: string;
    options?: any[];
    getOptionLabel?: (option: any) => any;
    icon?: React.ReactNode;
    iconAlign?: 'right' | 'left';
    sx?: object;
    inputStyle?: object;
}

export const SearchSelect: React.FC<SearchSelectProps> = ({ 
    value, 
    onFocus, 
    options = [], 
    onChange, 
    placeholder, 
    inputStyle = { background:theme.palette.background.default }, 
    icon, 
    iconAlign, 
    name, 
    sx = { width:'100%'}, 
    getOptionLabel,}) => {
    // Handle Autocomplete change
    const handleAutoCompleteChange = (event: React.SyntheticEvent, newValue: string | null) => {
        if (onChange) {
            const customEvent = {
                target: {
                    name: name || '', // Pass the name prop of the SearchSelect
                    value: newValue, // Pass the selected value(s)
                },
            } as React.ChangeEvent<{ name?: string; value: unknown }>;
            onChange(customEvent);
        }
    };
    
    return (
        <Autocomplete
            options={options} // Options for the dropdown
            value={value} // Current value of the autocomplete
            onFocus={onFocus}
            getOptionLabel={getOptionLabel}
            onChange={handleAutoCompleteChange}
            sx={sx}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    placeholder={placeholder}
                    InputProps={{
                        ...params.InputProps, // Ensures dropdown arrow and clear icon render properly
                        disableUnderline: true, // Remove underline
                        startAdornment: icon && iconAlign === 'left' ? icon : null, // Conditionally show the search icon
                        endAdornment: icon && iconAlign === 'right' ? icon : null, // Conditionally show the search icon
                        style: {
                            ...inputStyle,
                            borderRadius: '8px',
                            border: 'none',
                            padding: '8px',
                            minWidth:'200px',
                        },
                    }}
                />
            )}
        />
    );
};