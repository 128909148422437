import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import { SearchSelect } from "../../components/common/SearchSelect";
import { Column, TableWidget } from "../../components/widgets/TableWidget";
import { Search } from "@mui/icons-material";
import useAPI from "../../hooks/useAPI";
import { Employee as EmployeeEntity} from "../../models/Employee";
import { useModal } from "../../contexts/ModalContext";

const personnelColumn: Column[] = [
    { header: 'Nr.', accessor: 'id', align: 'left' },
    { header: 'Namn', accessor: 'name', align: 'left' },
    { header: 'Anställningsdatum', accessor: 'employmentDate', align: 'center' },
    { header: 'Starttid', accessor: 'startTime', align: 'center' },
    { header: 'Sluttid', accessor: 'endTime', align: 'center' },
    { header: 'Anställningsform', accessor: 'employmentType', align: 'center' },
    { header: 'Frånvaro', accessor: 'absence', align: 'right' },
];

export const Employee : React.FC = () =>{
    // Use modal context
    const { open } = useModal();
    const [personnelData, setPersonnelData] = useState<EmployeeEntity[]>([]);
    const {data, isLoading, error, callAPI} = useAPI<EmployeeEntity[]>("/employee");
    // handle OnEdit 
    const handleOnEdit = () => {
    }
    // handle Create Personnel
    const handleCreatePersonnel = () => {
        open("EmployeeModal");
    }
    // Call api to fetch employees
    useEffect(() => {
        callAPI();
    },[]);
    // Populate table data
    useEffect(() => {
        if(data){
            setPersonnelData(data);
        }
        
    },[data]);

    return(
        <PageLayout
            title="Medarbetare"
            onEdit={handleOnEdit}
            extras={[
                <Button variant='contained' onClick={handleCreatePersonnel} >Skapa</Button>,    
                <SearchSelect inputStyle={{background:'white'}} placeholder="Sök" icon={<Search/>} iconAlign="right"/>  
            ]}
        >
            <TableWidget title="Medarbetare Lista" size={12} data={personnelData} columns={personnelColumn}/>
        </PageLayout>
    );
} 
