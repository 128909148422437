import { Alert, AlertProps, Container } from "@mui/material";
import React, { createContext, useContext, useState } from "react";

// Alert props
interface AlertContextProps {
  alert: (severity: AlertProps['severity'], message: string, callback?: () => void, seconds?: number) => void;
}

const AlertContext = createContext<AlertContextProps | null>(null);

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [display, setDisplay] = useState<boolean>(false);
  const [severity, setSeverity] = useState<AlertProps['severity']>('info'); // Explicit type
  const [message, setMessage] = useState<string>('');

  const alert = (severity: AlertProps['severity'] = 'info', message: string = '', callback?: () => void, seconds = 5) => {
    setSeverity(severity);
    setMessage(message);
    setDisplay(true);

    // Start a timer to close alert after 5 seconds
    setTimeout(() => {
      setDisplay(false);
      if (callback) callback(); // Execute the callback if provided
    }, seconds * 1000);
  };

  return (
    <AlertContext.Provider value={{ alert }}>
      {children}
      <Container
        sx={{
          position: "fixed",
          bottom: 20,
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          zIndex: 1500, // Ensure it's above modals
          display: "flex",
          justifyContent: "center",
          pointerEvents: "none", // Prevent interaction issues
        }}
      >
        {display && (
          <Alert variant="filled" severity={severity}>
            {message}
          </Alert>
        )}
      </Container>
    </AlertContext.Provider>
  );
};

// Custom hook for accessing the alert context
export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert should be used inside AlertProvider");
  }
  return context;
};