import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageLayout from "../../components/layouts/PageLayout";
import { SearchSelect } from "../../components/common/SearchSelect";
import { Column, TableWidget } from "../../components/widgets/TableWidget";
import { VehiclesModal } from "./VehiclesModal";
import { Search } from "@mui/icons-material";

interface VehicleTable {
    id: number;
    regNumber:string,
    model: string;
    yearModel:string,
    dateOfUse:string,
    consumption:string,
    fuelType:string,
    gearbox:'Manuell' | "Automat",
}

const vehicleColumn: Column[] = [
    { header: 'Nr.', accessor: 'id', align: 'left' },
    { header: 'Reg.Nr', accessor: 'regNumber', align: 'left' },
    { header: 'Modell', accessor: 'model', align: 'center' },
    { header: 'Årsmodell', accessor: 'yearModel', align: 'center' },
    { header: 'Bruksdatum', accessor: 'dateOfUse', align: 'center' },
    { header: 'Förbrukning', accessor: 'consumption', align: 'center' },
    { header: 'Drivmedel', accessor: 'fuelType', align: 'right' },
    { header: 'Växellåda', accessor: 'gearbox', align: 'right' },
];

const vehicleData : VehicleTable[] = [
    {
        id:1,
        regNumber:'SKK694',
        model:'Mercedes GT 63s',
        yearModel:'2022',
        dateOfUse:'2024-01-01',
        consumption:'1l/mil',
        fuelType:'Bensin',
        gearbox:'Automat',
    },
    {
        id:1,
        regNumber:'SKK694',
        model:'Mercedes GT 63s',
        yearModel:'2022',
        dateOfUse:'2024-01-01',
        consumption:'1l/mil',
        fuelType:'Bensin',
        gearbox:'Automat',
    },
    {
        id:1,
        regNumber:'SKK694',
        model:'Mercedes GT 63s',
        yearModel:'2022',
        dateOfUse:'2024-01-01',
        consumption:'1l/mil',
        fuelType:'Bensin',
        gearbox:'Automat',
    },
    {
        id:1,
        regNumber:'SKK694',
        model:'Mercedes GT 63s',
        yearModel:'2022',
        dateOfUse:'2024-01-01',
        consumption:'1l/mil',
        fuelType:'Bensin',
        gearbox:'Automat',
    },

];


export const Vehicles : React.FC = () =>{
    // handle OnEdit
    const handleOnEdit = () => {
    }
    // handle Create Vehicle
    const handleCreateVehicle = () => {

    }

    return(
        <PageLayout
            title="Fordon"
            onEdit={handleOnEdit}
            extras={[
                <Button variant='contained' onClick={handleCreateVehicle} >Skapa</Button>,    
                <SearchSelect inputStyle={{background:'white'}} placeholder="Sök" icon={<Search/>} iconAlign="right"/>     
            ]}
        >
            <TableWidget title="Fordon Lista" size={12} data={vehicleData} columns={vehicleColumn}/>
        </PageLayout>
    );
} 
